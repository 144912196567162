import React from 'react';

function OurServices() {
  const items = [
    {
      name: 'Strategy & Innovation',
    },
    {
      name: 'Product Design',
    },
    {
      name: 'Technology & Development',
    },
    {
      name: "Security & Compliance's",
    },
    {
      name: 'Operations',
    },
    {
      name: 'Marketing & Community',
    },
  ];
  return (
    <section className='our-services' id='our_services'>
      <h4>{'<OUR SERVICES />'}</h4>

      <div
        className='tw-flex tw-flex-col lg:tw-flex-row tw-items-center lg:tw-items-start'
        //         fxLayout="row"
        //    fxLayout.lt-md="column"
        //    fxLayoutAlign="start start"
        //    fxLayoutAlign.lt-md="start center"
      >
        <p
          className='w-40'
          // fxFlex="40" fxFlex.lt-md="100"
        >
          We're an agile team focused on building the next generation of Web3.
          Our engineers have been working in the blockchain space since 2017.
        </p>

        <div
          className='offerings tw-flex tw-flex-col w-40 l-20'
          //     fxFlex.lt-md="100"
          // fxFlexOffset.xs="0"
          // fxFlexOffset.sm="0"
          // fxFlexOffset.md="10"
          // fxFlexOffset.gt-md="20"
          //  fxLayout="column"
        >
          {items.map((item) => {
            return (
              <div
                className='text-two item tw-flex tw-flex-row tw-items-center tw-place-content-start'
                //                 fxLayout="row"
                //                 fxLayoutAlign="start center"
              >
                <div className='name' fxFlex>
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default OurServices;
